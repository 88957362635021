/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "config",
  "supportedProducts",
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "templates/computeResourceCollections/matlab/collection",
  "templates/computeResourceCollections/cluster/collection",
  "templates/computeResourceCollections/parallelServer/collection",
  "templates/computeResourceCollections/productionServer/collection",
  "templates/computeResourceCollections/webAppServer/collection",
  "templates/computeResourceCollections/onlineServer/collection",
  "templates/computeResourceCollections/licenseManager/collection",
  "templates/computeResourceCollections/cfeDemo/collection",
  "templates/computeResourceCollections/cloudNetwork/collection"
], function (_, $, Config, SupportedProducts, Util, I18NStringResource,
             DojoString, MatlabTemplate, ClusterTemplate,
             ParallelServerTemplate, ProductionServerTemplate,
             WebAppServerTemplate, OnlineServerTemplate, LicenseManagerTemplate,
             CFEDemoTemplate, CloudNetworkTemplate) {

   const PRODUCT_TEMPLATES = {
     'matlab': MatlabTemplate,
     'cluster': ClusterTemplate,
     'parallel_server': ParallelServerTemplate,
     'online_server': OnlineServerTemplate,
     'production_server': ProductionServerTemplate,
     'web_app_server': WebAppServerTemplate,
     'license_manager': LicenseManagerTemplate,
     'cfe_demo': CFEDemoTemplate,
     'cloud_network': CloudNetworkTemplate
   };

   const GET_PRODUCT_TEMPLATE = (product) => {
    let template = null;
    const products = SupportedProducts.getProductNameList();
    if (products.includes(product) && (product) in PRODUCT_TEMPLATES) {
      template = PRODUCT_TEMPLATES[product];
    }
    return template;
  };

  class ProductUIGenerator {
    constructor() {
      this.credentialData = null;
    }

    generateProductHTML (product, templateParams = {}) {
      if (!product || typeof product !== 'string') {
        throw new TypeError('Invalid product argument');
      }
      if (product === 'parallel_server' || product === 'cluster') {
        const hasBothCreds = this.hasAzureAndAWSCredentials();
        templateParams.hasAzureAndAWSCredentials = hasBothCreds;
        templateParams.hasAWSCredential = this.getCredentialData().credentials.aws;
        templateParams.legacyClusterEnabled = this.getCredentialData().credentials.legacyClusterEnabled
        templateParams.newClusterEnabled = this.getCredentialData().credentials.newClusterEnabled
      }
      let productTableHTML = "";
      const template = GET_PRODUCT_TEMPLATE(product);
      if (template) {
        productTableHTML = template(templateParams);
      }
      return productTableHTML;
    }

    getProductsHTML (products) {
      if (!products) {
        throw new TypeError("Invalid products argument");
      }
      let productsHTML = "";
      const supportedProductNames = SupportedProducts.getProductNameList();
      for (let product of supportedProductNames) {
        if (products[product]) {
          const templateParams = {
            i18n: I18NStringResource,
            availableProducts: products
          };
          if (product === 'cluster') {
            templateParams["legacyURL"] = Config.getLegacyParallelURL();
          }
          const productHTML = this.generateProductHTML(product, templateParams);
          productsHTML += productHTML;
        }
      }
      return productsHTML;
    }

    getCredentialData () { return this.credentialData; }
    setCredentialData (data) {
      if (data && typeof data === "object" && typeof data.credentials === 'object') {
        this.credentialData = data;
      } else {
        this.credentialData = null;
      }
    }

    hasAzureAndAWSCredentials () {
      let hasBoth = false;
      if (this.getCredentialData()) {
        const credData = this.getCredentialData().credentials;
        const products = this.getCredentialData().products;
        if (credData.azure && credData.aws) {
          if (products && 'parallel_server' in products && 'cluster' in products) {
            hasBoth = true;
          }
        }
      }
      return hasBoth;
    }

  }
  return ProductUIGenerator;
});
