/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "service/cloudStatus",
  "service/cloudResource",
  "util",
  "supportedProducts",
  "service/landingPageNavButtons",
  "notification/notificationManager",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "statusAndCountdown",
  "components/editableText",
  "service/datatransform/ruleInfo",
  "service/specialCommands/specialCommandsManager"
], function (_, $, CloudStatus, CloudResource, Util, SupportedProducts,
  LandingPageNavButtons, NotificationManager, ComputeValueFormatter,
  I18NStringResource, DojoString, StatusAndCountdown, EditableText,
  RuleInfo, SpecialCommandsManager) {

  class CollectionItem {
    constructor(args) {
      if (this.constructor === CollectionItem) {
        throw new Error('Cannot instantiate abstract class.');
      } else {
        if (!this.constructorArgsAreValid(args)) {
          throw new TypeError('Invalid contructor args');
        }
        this.config = args.config;
        this.collection = args.collection;
        this.resourceType = args.resourceType;
        this.cloudResource = null;
        this.statusAndCountdown = null;
        this.rowElement = this.createRow();
      }
    }

    constructorArgsAreValid(args) {
      let isValid = false;
      if (args && typeof args === 'object' && 'config' in args && typeof args.config === 'object') {
        if ('id' in args.config && typeof args.config.id === 'string') {
          if ('params' in args.config && typeof args.config.params === 'object') {
            isValid = true;
          }
          if (
            !(
              'cloud' in args.config &&
              typeof args.config.cloud === 'object' &&
              'account' in args.config.cloud &&
              typeof args.config.cloud.account === 'object'
            )
          ) {
            Util.consoleLogWarning('constructorArgsAreValid', 'Resource config missing cloud account data');
          }
        }
      }
      if (isValid) {
        isValid = args.collection && typeof args.collection === 'object' && typeof args.collection.getDataService === 'function';
      }
      return isValid;
    }

    getUpdateOnDeleteInterval() {
      return CollectionItem.PAUSE_UPDATE_STATUS_ON_DELETE_INTERVAL;
    }

    getRefreshStatusInterval() {
      return CollectionItem.REFRESH_STATUS_INTERVAL;
    }

    getRefreshStatusIterationLimit() {
      return CollectionItem.REFRESH_STATUS_ITERATION_LIMIT;
    }

    getConfig() {
      return this.config;
    }

    getConfigId() {
      return this.getConfig().id;
    }

    getStatus() {
      return this.getCloudResource().getStatus();
    }

    getElement() {
      return this.rowElement;
    }

    getElementSelector() {
      return `li[data-id="${this.getConfigId()}"].item.item-container`;
    }

    getCollection() {
      return this.collection;
    }

    getCloudResource() {
      return this.cloudResource;
    }

    setCloudResource(cloudResource) {
      this.cloudResource = cloudResource;
    }

    getResourceType() {
      return this.resourceType;
    }

    setStatusAndCountdown(statusAndCountdown) {
      this.statusAndCountdown = statusAndCountdown;
    }
    getStatusAndCountdown() {
      return this.statusAndCountdown;
    }

    displayResourceNotifications(event) {
      let notifications = '';
      if (event && event.preventDefault) {
        event.preventDefault();
        let btn = event.currentTarget;
        if (btn && btn.tagName === 'BUTTON' && btn.classList.contains('displayNotificationsBtn')) {
          let parent = btn.parentElement;
          if (parent && parent.dataset.notifications) {
            notifications = parent.dataset.notifications;
          }
        }
      }
      if (notifications) {
        // alert(notifications);
        Util.notify('RESOURCE_ERROR', `${this.config.params['mw-name']}: ${notifications}`);
      }
    }

    /* istanbul ignore next */
    getResourceCollectionItemTemplate() {
      throw new Error(`Abstract method.`);
    }

    createResourceRowFromTemplate(templateParams, args = { eol: {} }) {
      const collection = document.createElement('ol');
      const resourceCollectionItemTemplate = this.getResourceCollectionItemTemplate();
      if (!resourceCollectionItemTemplate) {
        throw new Error(`No resource collection item template found for product "${this.getResourceType()}".`);
      }
      const resourceRowHTML = resourceCollectionItemTemplate({
        params: templateParams,
        args: args,
        I18NStringResource: I18NStringResource
      });

      collection.innerHTML = resourceRowHTML;
      const resourceRow = collection.querySelector('li');

      // Setup update method for EditableText elements so they know how to update the name
      const editableTextElements = resourceRow.querySelectorAll('editable-text');
      for (const el of editableTextElements) {
        const product = this.getResourceType();
        const validateOnly = false;
        el.updateMethod = (async (data) => {
          let result = false;
          const workflowDataService = this.getCollection().getDataService().workflow;
          try {
            await workflowDataService.update(data.resourceId, data.params, validateOnly, product);
            result = true;
            // update CloudResource
            const cloudResource = this.getCloudResource();
            if (cloudResource) {
              cloudResource.setName(data.params['mw-name']);
              if (cloudResource.getConfig()) {
                cloudResource.getConfig().params['mw-name'] = data.params['mw-name'];
              }
            }
          } catch (error) {
            Util.consoleLogWarning('updateMethod', error);
          }
          return result;
        }).bind(this);
      }

      const btn = resourceRow.querySelector('button.btn.displayNotificationsBtn:not(.disabled)');
      if (btn) {
        btn.addEventListener('click', this.displayResourceNotifications.bind(this), false);
      }

      const statusMarker = resourceRow.querySelector('div.statusAndCountdownContainer');
      if (statusMarker) {
        this.setStatusAndCountdown(
          new StatusAndCountdown({
            supportsTermPolicy: templateParams.support_termination_policy
          })
        );
        this.getStatusAndCountdown().setElement(statusMarker);
        this.getStatusAndCountdown().render();
      }

      return resourceRow;
    }

    getCreateCollectionItemTemplateParams() {
      const config = this.getConfig();
      if (!CloudResource.isConfigValid(config)) {
        throw new Error('Config object is not set or is invalid');
      }
      const notificationArray = config.cloud.error || [];
      const templateParams = {
        id: config.id,
        resourceType: Util.convertProductToUserFriendlyProduct(this.getResourceType()),
        name: _.escape(config.params['mw-name']),
        product: ComputeValueFormatter.getRelease(_.escape(config.params.version)),
        notification_count: notificationArray.length,
        combined_notification: _.escape(Util.extractNotificationFromCloudErrors(notificationArray)),
        cloud_provider: ComputeValueFormatter.getCloudProvider(_.escape(config.params.cloud_provider)),
        cloud_account: _.escape(config.cloud.account.account_id),
        cloud_location: ComputeValueFormatter.getCloudLocation(_.escape(config.params['mw-cloud-location'])),
        machine_type: ComputeValueFormatter.getMachineType(_.escape(config.params.InstanceType)),
        operating_system: ComputeValueFormatter.getOperatingSystem(_.escape(config.params.operating_system)),
        support_termination_policy:
          config.params['mw-termination-policy'] > 0 ||
          config.params['mw-termination-policy'] === 'never' ||
          config.params['mw-termination-policy'] === 'on_idle'
      };
      for (const [k, v] of Object.entries(config.params)) {
        if (!templateParams[k]) {
          templateParams[k] = _.escape(v);
        }
      }
      return templateParams;
    }

    createRow() {
      const templateParams = this.getCreateCollectionItemTemplateParams();
      const eol = this.getConfig().eol || {};
      const args = {
        eol: eol,
        deprecated: eol && eol.Status === RuleInfo._EOLStatusValues['DEPRECATED'] ? true : false,
        sunsetted: eol && eol.Status === RuleInfo._EOLStatusValues['SUNSET'] ? true : false
      };

      const row = this.createResourceRowFromTemplate(templateParams, args);
      return row;
    }

    async updateCloudResource(providedConfig) {
      let config = providedConfig;
      const workflowDataService = this.getCollection().getDataService().workflow;
      if (!CloudResource.isConfigValid(config)) {
        throw new TypeError('Invalid providedConfig argument');
      }
      // update or create cloudResource with latest data
      if (this.getCloudResource()) {
        // update existing cloud resource object
        this.getCloudResource().refreshConfigData(config);
      } else {
        /*
          Make a new cloud resource object
        */
        // stop resource and update status function
        /* istanbul ignore next */
        const pauseAndRefresh = async function (configId) {
          workflowDataService.pause(configId, this.getResourceType());
          await this.updateStatus();
        };
        // bind function to this context
        const fnPause = pauseAndRefresh.bind(this, this.getConfigId());
        // constructor args for CloudResource class
        const crArgs = {
          dataService: this.getCollection().getDataService(),
          config: config,
          proxyURL: this.getCollection().getProxyURL(),
          fnStopResource: fnPause
        };
        const cloudResource = new CloudResource(crArgs);
        this.setCloudResource(cloudResource);
      }

      const product = this.getResourceType();
      const mwNamePrefix = `${this.getCloudResource().getName()}: `;
      const status = this.getCloudResource().getStatus();
      const statusTransitionPercentage = this.getCloudResource().getStatusTransitionPercentage();

      const settings = {
        config: this.getCloudResource().getConfig(),
        configId: this.getCloudResource().getConfigId(),
        product: product,
        mwNamePrefix: mwNamePrefix,
        status: status,
        statusTransitionPercentage: statusTransitionPercentage
      };

      return settings;
    }

    async reloadConfigIfTerminatedWithErrors(cachedData, originalSettings) {
      let settings = originalSettings;
      if (!cachedData && this.getCloudResource().resourceTerminatedWithErrors()) {
        //edge case when deleting:
        //if the status is terminated, check again in 10 seconds to see if the database record still exists
        //if it doesn't, the user deleted the record, so even if there's an error, we can remove it
        //
        // wait 10 seconds
        await new Promise((resolve) => setTimeout(resolve, this.getUpdateOnDeleteInterval()));
        const config = await this.getCollection().getDataService().ui.getLatestConfigData(this.getConfigId());
        settings = await this.updateCloudResource(config);
      }
      return settings;
    }

    removeComputeResourceRow() {
      const rowElement = this.getElement();
      if (rowElement) {
        const mwNamePrefix = rowElement.querySelector('div.resource-machine-name a').innerText;
        const container = rowElement.parentElement;
        if (container) {
          container.removeChild(rowElement);
          // table header is also an 'li' element
          if (container.querySelectorAll('li').length === 1) {
            SupportedProducts.showNoProductResourcesMessage(this.getResourceType());
          }
        }
        Util.notify('NORMAL', `${mwNamePrefix}: ${I18NStringResource.computeResourcePageDeleteComplete}`);
        this.getCollection().getDataService().workflow.listConfigsByProduct(this.getResourceType()); // trigger background refresh
      }
    }

    updateComputeResourceRow(settings, config, iteration) {
      const row = this.getElement();
      let buttons = this.createButtons(config);
      let ctaButtons = this.createCTAButtons(config);

      row.dataset.updating = 'false';
      this.updateIPRowElement(this.getCloudResource());
      let alternateStatusText = "";
      let errors = null;
      if (config.cloud && config.cloud.error) {
        errors = config.cloud.error;
        if (errors[0] && errors[0].status === 'CREATE_FAILED') {
          alternateStatusText = I18NStringResource.computeResourcePageStatusPausing;
        }
      }
      this.updateNotificationRowElement(this.getCloudResource(), errors);
      const statusTransitionPercent = Math.min(iteration * 1 + this.getCloudResource().getStatusTransitionPercentage(), 100);
      this.getStatusAndCountdown().renderStatus(settings.status, statusTransitionPercent, alternateStatusText);
      this.createMenuItems(buttons, ctaButtons);
      this.updateAutoTerminationSpinner(this.getCloudResource(), settings.status);
    }

    async getLatestConfigData(initialData = null) {
      let config = initialData;
      // if invalid config or config status is in transition (e.g. "Starting", "Stopping", etc.), get latest
      if (!config || typeof config !== 'object' || CloudStatus.isTransitionStatus(this.getConfigStatus(config).status)) {
        // clear cache, if data service supports it so we get latest data
        if (typeof this.getCollection().getDataService().clear === 'function') {
          this.getCollection().getDataService().clear('ui/getLatestConfigData');
        }
        config = await this.getCollection().getDataService().ui.getLatestConfigData(this.getConfigId());
      }
      return config;
    }

    async rowShouldBeRemoved(config, priorStatus, priorStartError) {
      let removeRow = false;
      /* istanbul ignore next */
      removeRow = Boolean(!CloudResource.isConfigValid(config) && priorStatus === CloudStatus.Enum.TERMINATING);
      if (!removeRow) {
        /* istanbul ignore next */
        const cloudEvents =
          config && config.cloud_events ? config.cloud_events : config && config.cloud && config.cloud.events ? config.cloud.events : null;
        /* istanbul ignore next */
        const cloudErrors =
          config && config.cloud_errors ? config.cloud_errors : config && config.cloud && config.cloud.error ? config.cloud.error : null;
        /* istanbul ignore next */
        const isNoConfigAndKnownStatus = Boolean(
          !config && this.getCloudResource() && this.getCloudResource().getStatus() !== CloudStatus.Enum.UNKNOWN
        );
        /* istanbul ignore next */
        const isConfigAndTerminatedWithNoErrors = Boolean(
          config &&
            config.cloud &&
            config.cloud.state === 'TERMINATED' &&
            (!cloudErrors || (cloudErrors && !cloudErrors.length)) &&
            (this.getCloudResource().getStatus() === CloudStatus.Enum.TERMINATED ||
              this.getCloudResource().getStatus() === CloudStatus.Enum.TERMINATING)
        );
        /* istanbul ignore next */
        const configHasAWSDeleteCompleteEvent = Boolean(
          !priorStartError &&
            cloudEvents &&
            cloudEvents.length &&
            cloudEvents[0].status === 'DELETE_COMPLETE' &&
            cloudEvents[0].type === 'AWS::CloudFormation::Stack' &&
            (cloudErrors === null || cloudErrors.length === 0)
        );
        //priorStatus is only passed in from interval, so waiting to remove row
        // Keep in mind a newly created resource will have status TERMINATED, no ERRORs so, isConfigAndTerminatedWithNoErrors would be true
        if (
          priorStatus === CloudStatus.Enum.TERMINATING &&
          !priorStartError &&
          (isNoConfigAndKnownStatus || isConfigAndTerminatedWithNoErrors)
        ) {
          removeRow = true;
        } else if (configHasAWSDeleteCompleteEvent) {
          // delete the config only when it's deleted complete and there is no error.
          // Call delete endpoint and check if it succeeds or kicks off shutdown first
          if (
            (await this.getCollection().getDataService().workflow.deactivate(this.getConfigId(), this.getResourceType())) ===
            'config deleted'
          ) {
            removeRow = true;
          }
        }
      }

      return removeRow;
    }

    getStatusFromRow() {
      let rowStatus;
      try {
        const sc = this.getStatusAndCountdown();
        const tempPriorStatus = sc.getCurrentStatus();
        if (tempPriorStatus !== CloudStatus.Enum.UNKNOWN) {
          rowStatus = tempPriorStatus;
        }
      } catch (error) {
        /* do nothing */
      }
      return rowStatus;
    }

    async updateStatus(cachedData, iteration = 1, priorStatus, priorStartError) {
      let config = null;
      let configSettings = {};

      try {
        // get prior status if missing
        if (!priorStatus) {
          priorStatus = this.getStatusFromRow();
        }

        // get latest config data
        config = await this.getLatestConfigData(cachedData);

        if (!priorStartError) {
          if (
            config &&
            config.cloud &&
            config.cloud.error &&
            config.cloud.error.length &&
            config.cloud.error[0].status === 'CREATE_FAILED'
          ) {
            priorStartError = 'CREATE_FAILED';
          }
        }

        // Check the case where the config was TERMINATING but, during the lapse of
        // time in checking the status, the config was already deleted.
        if (!config || (!CloudResource.isConfigValid(config) && priorStatus === CloudStatus.Enum.TERMINATING)) {
          this.removeComputeResourceRow();
          return;
        }

        try {
          configSettings = await this.updateCloudResource(config);
        } catch (updateCloudResourceError) {
          if (Util.currentPageIsExpectedPage(this.getCollection().getExpectedPage())) {
            throw updateCloudResourceError;
          }
        }

        // if this is first iteration and resource is starting, put up notification
        if (iteration === 1 && CloudStatus.isStartingUpStatus(configSettings.status)) {
          Util.notify('INFO', `${configSettings.mwNamePrefix}${I18NStringResource.computeResourceLaunchInfo}`);
        }

        // check for termination conditions and update config, cloudResource
        configSettings = await this.reloadConfigIfTerminatedWithErrors(cachedData, configSettings);
        config = configSettings.config; // get updated config

        if (await this.rowShouldBeRemoved(config, priorStatus, priorStartError)) {
          this.removeComputeResourceRow();
          return;
        }

        // update the UI for this row
        this.updateComputeResourceRow(configSettings, config, iteration);
      } catch (updateStatusError) {
        Util.consoleLogError('updateStatus', updateStatusError);
        Util.notify('ERROR', I18NStringResource.computeResourcePageErrorManualRefreshRequired);
      }

      if (configSettings.status) {
        this.repeatRowRefresh(iteration, configSettings.status, priorStartError);
      }
    }

    getRepeatUpdateFunction(originalExpectedContainerElement, iteration, status, priorStartError) {
      /* istanbul ignore next */
      const fn = async function () {
        try {
          // the actual element that exists at the time this setTimeout function is called.
          // may not be the same as the original -- means we left the page and came back.
          let expectedContainer = document.querySelector(`div#${this.getCollection().getExpectedPage()}.pageView`);
          if (expectedContainer && expectedContainer === originalExpectedContainerElement) {
            await this.updateStatus(undefined, iteration, status, priorStartError);
          }
        } catch (error) {
          Util.consoleLogError('getRepeatUpdateFunction', error);
          Util.notify('ERROR', I18NStringResource.computeResourcePageErrorManualRefreshRequired);
        }
      }.bind(this);
      return fn;
    }

    repeatRowRefresh(iteration, status, priorStartError) {
      const options = this.getCloudResource().getOptions();
      if (options.autoRefresh) {
        ++iteration;
        if (iteration < this.getRefreshStatusIterationLimit()) {
          // the actual element being used when the refresh process is kicked off.
          const originalExpectedContainerElement = document.querySelector(`div#${this.getCollection().getExpectedPage()}.pageView`);
          //if someone deletes a starting resource, don't continue to refresh it's start call
          setTimeout(
            this.getRepeatUpdateFunction(originalExpectedContainerElement, iteration, status, priorStartError),
            this.getRefreshStatusInterval()
          );
        }
      } else {
        NotificationManager.removeNotification(this.getCloudResource().getName());
        this.getCollection().getDataService().workflow.listConfigsByProduct(this.getResourceType()); // trigger background refresh
      }
    }

    getConfigStatus(config) {
      let status = CloudStatus.Enum.UNKNOWN;
      let statusTransitionPercent = 0;
      if (config && config.cloud && config.cloud.state) {
        status = CloudStatus.getStatus({ state: config.cloud.state });
      }
      if (config && config.cloud && config.cloud.state_percent && !isNaN(parseFloat(config.cloud.state_percent))) {
        statusTransitionPercent = parseFloat(config.cloud.state_percent) * 100;
      }
      if (!statusTransitionPercent && CloudStatus.isTransitionStatus(status)) {
        statusTransitionPercent = 1;
      }
      let statusData = {
        status: status,
        percent: statusTransitionPercent
      };
      return statusData;
    }

    makeRowRefreshHandler() {
      /* istanbul ignore next */
      let fn = async function () {
        await this.updateStatus();
        return false;
      }.bind(this);
      return fn;
    }

    makeCloneClickHandler() {
      /* istanbul ignore next */
      let fn = async function (event) {
        try {
          let data = await this.getCollection().getDataService().workflow.clone(this.getConfigId(), this.getResourceType());
          if (data && data.id) {
            let newConfig = {};
            Object.assign(newConfig, this.getConfig());
            newConfig.id = data.id;
            let newName = this.getConfig().params['mw-name'] + ' (1)';
            try {
              await this.getCollection()
                .getDataService()
                .workflow.update(newConfig.id, { 'mw-name': newName }, false, this.getResourceType());
              newConfig.params['mw-name'] = newName;
            } catch (error) {
              Util.consoleLogError('makeCloneClickHandler', error);
            }
            newConfig.cloud_machines = null;
            newConfig.cloud.state = 'TERMINATED';
            this.getCollection().appendResourceItemToList(newConfig);

            Util.notify(
              'NORMAL',
              DojoString.substitute(I18NStringResource.computeResourcePageActionCloneSuccessWithInfo, [newConfig.params['mw-name']])
            );
            return;
          }
          Util.notify('WARNING', DojoString.substitute(I18NStringResource.computeResourcePageActionCloneErrorWithInfo, ['']));
        } catch (error) {
          Util.consoleLogError('makeCloneClickHandler', error);
          const subError = Util.getServerError(error);
          if (error.errorCode === 'error.is_sunsetted') {
            Util.notify('ERROR', DojoString.substitute(I18NStringResource.computeResourcePageActionCloneSunsetErrorWithInfo, [subError]));
          } else {
            Util.notify('WARNING', DojoString.substitute(I18NStringResource.computeResourcePageActionCloneErrorWithInfo, [subError]));
          }
        }
      }.bind(this);
      return fn;
    }

    getConfigName() {
      return this.getCloudResource().getName();
    }

    getMenuOptions() {
      return this.getCloudResource().getOptions();
    }

    processCTAMenuItem(menuItem, hasCTA, buttonList, buttons, ctaButtons) {
      const item = buttons[menuItem];
      let cta;
      let uiName = menuItem;
      if (menuItem === 'resume') {
        uiName = 'start';
      }
      if (menuItem === 'pause') {
        uiName = 'stop';
      }
      if (!hasCTA) {
        cta = ctaButtons[`${menuItem}CTA`];
        delete buttonList[uiName];
      } else {
        buttonList[uiName] = { btn: item, disabled: false };
      }
      return [item, cta];
    }

    processNormalMenuItem(menuItem, buttonList, buttons) {
      const config = this.getConfig();
      const eol = config.eol;
      const isSunsetted = Boolean(eol && eol.Status === 3);
      let item = buttons[menuItem];
      if (!item) {
        buttons[`cta-${menuItem}`];
        if (item) {
          menuItem = `cta-${menuItem}`;
        }
      }

      let uiName = menuItem;
      if (menuItem === 'hard-delete' || menuItem === 'archive') {
        uiName = 'remove';
      }
      buttonList[uiName] = { btn: item, disabled: false };
      if (uiName === 'start' && isSunsetted) {
        buttonList[uiName].disabled = true;
      }
      return item;
    }

    processMenuItems(menuItems, buttonList, buttons, ctaButtons) {
      let cta;
      let firstMenuItem = true;
      const ctaMenuItems = ['start', 'resume', 'stop', 'pause'];

      if (menuItems && Array.isArray(menuItems) && menuItems.length) {
        // process in order
        for (let menuItem of menuItems) {
          let item;
          if (ctaMenuItems.includes(menuItem)) {
            [item, cta] = this.processCTAMenuItem(menuItem, cta, buttonList, buttons, ctaButtons);
            this.processNormalMenuItem(menuItem, buttonList, buttons);
          } else {
            item = this.processNormalMenuItem(menuItem, buttonList, buttons);
          }
          if (item) {
            if (firstMenuItem && cta) {
              // the first item in menuItems is the primary Call To Action, which is only shown in mobile.
              item.className = `${item.className} resource-menu-mobile`;
            }
            firstMenuItem = false;
          }
        }
      }
      return cta;
    }

    populateMenuFromButtonList(menuContentContainer, buttonList, row, status) {
      let keys = Object.keys(buttonList);
      let cloudLinkCTAs = [];
      let utilCTAs = [];
      let remainder = [];
      let hasCTA = false;
      while (keys.length) {
        // && keys[0]
        const key = keys.shift();
        const value = buttonList[key];
        if (key.startsWith('cta-')) {
          hasCTA = true;
        }
        if (value && value.btn && value.btn.dataset && value.btn.dataset.cloudlink_id) {
          value.btn.dataset.key = key;
          cloudLinkCTAs.push(value.btn.dataset);
        } else if (key.startsWith('cta-')) {
          utilCTAs.push(key);
        } else {
          remainder.push(key);
        }
      }
      cloudLinkCTAs.sort((a, b) => {
        if (a.cloudlink_sort_order !== undefined) {
          //could be 0
          try {
            let aOrder = parseInt(a.cloudlink_sort_order);
            let bOrder = parseInt(b.cloudlink_sort_order);
            if (aOrder > bOrder) {
              return 1;
            } else if (aOrder < bOrder) {
              return -1;
            }
          } catch (err) {} //ignore
        }
        if (a.cloudlink_id) {
          return a.cloudlink_id.localeCompare(b.cloudlink_id);
        }
        return 0;
      });
      let clCTAKeys = cloudLinkCTAs.reduce((acc, cta) => {
        const key = cta.key;
        acc.push(key);
        return acc;
      }, []);

      keys = clCTAKeys.concat(utilCTAs).concat(remainder);

      let ctaDisabled = true;

      if (hasCTA) {
        const connectButton = row.querySelector('#connectCtaBtn');
        if (connectButton) {
          ctaDisabled = connectButton.disabled;
        }
      }

      for (const key of keys) {
        if (key === 'outputs') {
          continue;
        }

        const value = buttonList[key];
        if (value && value.btn) {
          if (hasCTA) {
            if (key.startsWith('cta-')) {
              value.disabled = (status !== CloudStatus.Enum.RUNNING); // ctaDisabled;
              if (value.btn.dataset.cloudlink_always_available === 'true') {
                //=== 'true' b/c dataset converts bools to strings
                value.disabled = false;
              }
            } else {
              const horizontalLine = document.createElement('hr');
              menuContentContainer.appendChild(horizontalLine);
              hasCTA = false;
            }
          }

          const span = value.btn;
          const btn = span.querySelector('button');
          btn.classList.add('dropdown-item');
          const li = document.createElement('li');
          btn.disabled = value.disabled;

          let unclickable = false;
          if (value.btn.dataset && value.btn.dataset.cloudlink_id) {
            unclickable = value.btn.dataset.cloudlink_link === '' && value.btn.dataset.cloudlink_proxy === 'false';
          }

          if ((btn.disabled && ctaDisabled) || unclickable) {
            btn.classList.add('disabled');
          } else {
            btn.classList.remove('disabled');
          }

          if (btn.id === 'cta-clusterprofile' || btn.id === 'connectCtaBtn') {
            const clusterSharedState = this.getConfig().params['mw-cluster-shared-state'];
            if (clusterSharedState === 'Personal') {
              btn.disabled = true;
              btn.classList.add('disabled');
            }
          }

          // If this is a special command button, override the previous settings
          // by checking what this button should be, based on the current status
          if (SpecialCommandsManager.getSpecialButtonList().includes(key)) {
            const menuItems = CloudStatus.getMenuItems(status);
            const btnEnabled = menuItems.includes(key);
            if (btnEnabled) {
              btn.disabled = false;
              btn.classList.remove('disabled');
            } else {
              btn.disabled = true;
              btn.classList.add('disabled');
            }
          }
          li.appendChild(btn);
          menuContentContainer.appendChild(li);
        }
      }
    }

    createMenuSubsection(menuContentContainer, id = '') {
      const li = document.createElement('li');
      const header = document.createElement('label');
      header.classList.add('dropdown-header', 'menuSubsection');
      header.textContent = I18NStringResource.computeResourcePageActionOutputsSubmenuTitle;
      header.for = id;
      li.appendChild(header);
      const subsectionList = document.createElement('ul');
      subsectionList.id = id;
      subsectionList.classList.add('menuSubsectionList');
      li.appendChild(subsectionList);
      menuContentContainer.appendChild(document.createElement('hr'));
      menuContentContainer.appendChild(li);
      return subsectionList;
    }

    appendButtonToMenuSubsectionList(buttonContainer, subsectionList, isDisabled = false) {
      const btn = buttonContainer.querySelector('button');
      btn.classList.add('dropdown-item');
      if (isDisabled) {
        btn.classList.add('disabled');
        btn.disabled = true;
      }
      const actionli = document.createElement('li');
      actionli.appendChild(btn);
      subsectionList.appendChild(actionli);
    }

    appendClipboardCloudLinkButtonsToMenuSubsection(menuContentContainer, subsectionListId, clbtns, status) {
      try {
        const isDisabled =
          status === CloudStatus.Enum.STARTING ||
          status === CloudStatus.Enum.STARTED ||
          status === CloudStatus.Enum.TERMINATED ||
          status === CloudStatus.Enum.TERMINATING ||
          status === CloudStatus.Enum.UNKNOWN;
        const subsectionList = menuContentContainer.querySelector(`ul#${subsectionListId}`);
        if (clbtns && Array.isArray(clbtns) && clbtns.length) {
          for (const span of clbtns) {
            const btn = span.querySelector('button');
            btn.classList.add('dropdown-item');
            if (isDisabled) {
              btn.classList.add('disabled');
              btn.disabled = true;
            }
            const actionli = document.createElement('li');
            actionli.appendChild(btn);
            subsectionList.appendChild(actionli);
          }
        }
      } catch (error) {
        Util.consoleLogWarning('appendClipboardCloudLinkButtonsToMenuSubsection', error);
      }
    }

    populateCTAContainer(row, cta, buttons, status) {
      const config = this.getConfig();
      const eol = config.eol;
      const isSunsetted = Boolean(eol && eol.Status === 3);
      const ctaButtons = cta.querySelectorAll('button');
      const isStartBtn = Boolean(ctaButtons.length && cta.querySelectorAll('button')[0].id === 'startCtaBtn');
      const ctaContainer = row.getElementsByClassName('cta')[0];
      const connectBtn = buttons.connect.querySelector('button');
      if (ctaContainer) {
        ctaContainer.innerHTML = '';
        if (cta) {
          ctaContainer.appendChild(cta);
        }
        if ((connectBtn.id === 'cta-clusterprofile')) {
          const clusterSharedState = config.params['mw-cluster-shared-state'];
          if (clusterSharedState === 'Personal') {
            connectBtn.disabled = true;
            connectBtn.classList.add('disabled');
          }
        }
        ctaContainer.appendChild(buttons.connect); // add secondary button here
        if (
          cta &&
          (status === CloudStatus.Enum.STARTED ||
            status === CloudStatus.Enum.STARTING ||
            status === CloudStatus.Enum.TERMINATING ||
            status === CloudStatus.Enum.PAUSING ||
            (isSunsetted && isStartBtn))
        ) {
          const button = cta.querySelector('button');
          if (button) {
            button.classList.add('disabled');
            button.disabled = true;
          }
        }
      } else {
        throw new Error('Unable to find element with classname cta in row.');
      }
    }

    getConnectButton() {
      return this.getCloudResource().getConnectButton();
    }

    getLicenseManagerConnectButton() {
      return this.getCloudResource().getLicenseManagerConnectButton();
    }

    getRDPButton() {
      return this.getCloudResource().buttonRDP();
    }

    getDCVButton() {
      return this.getCloudResource().buttonDCV();
    }

    getGenericConnectButton(btnText, btnId, btnIdOverride) {
      return this.getCloudResource().getGenericConnectButton(btnText, btnId, btnIdOverride);
    }

    createMenuItems(buttons, ctaButtons) {
      const status = this.getStatus();
      const row = this.getElement();
      const menuContainer = LandingPageNavButtons.createMenuStructureAndGetContainer(
        row.getElementsByClassName('context-menu')[0],
        I18NStringResource.computeResourcePageActionMoreOptions,
        I18NStringResource.computeResourcePageActionMoreOptionsTooltip
      );
      const menuContentContainer = menuContainer.querySelector('div.menuContent');

      if (menuContentContainer) {
        //add hidden name for mobile
        const nameLi = LandingPageNavButtons.createMobileOnlyMenuTextItem(this.getConfigName());
        menuContentContainer.appendChild(nameLi);

        //default buttons
        const buttonList = this.getDefaultButtonList(buttons, status);

        //add the menu configs
        const options = this.getMenuOptions();
        let menuItems = [];
        if (options && options.menuItems) {
          menuItems = options.menuItems;
        }
        for (const [id, btn] of Object.entries(buttonList)) {
          if (id.startsWith('cta-')) {
            menuItems.push(id);
          }
        }

        const cta = this.processMenuItems(menuItems, buttonList, buttons, ctaButtons);

        this.populateCTAContainer(row, cta, buttons, status);
        this.populateMenuFromButtonList(menuContentContainer, buttonList, row, status);
        const copyButtonGroup = buttonList.copybtns;
        const subsectionListId = 'copyList';
        this.populateMenuSubsection(menuContentContainer, subsectionListId, copyButtonGroup);
        // append any clipboard_ outputs to the subsectionList
        const clbtns = buttonList['outputs'];
        if (clbtns) {
          this.appendClipboardCloudLinkButtonsToMenuSubsection(menuContentContainer, subsectionListId, clbtns, status);
        }
      }
    }

    populateMenuSubsection(menuContentContainer, subsectionListId, copyButtonGroup) {
      const menuSubsectionList = this.createMenuSubsection(menuContentContainer, subsectionListId);
      if (copyButtonGroup) {
        for (const btnItem of copyButtonGroup) {
          const btn = btnItem.btn;
          this.appendButtonToMenuSubsectionList(btn, menuSubsectionList, false);
        }
      }
    }

    getDefaultButtonList(allButtons, status) {
      let btns = {};
      btns.copybtns = [];
      // add CTA first and it will show up first
      for (const [id, btn] of Object.entries(allButtons)) {
        if (id.startsWith('cta-')) {
          btns[id] = { btn: btn, disabled: true };
        } else if (id.startsWith('copy-')) {
          btns.copybtns.push({ id: id, btn: btn });
        }
      }
      // remove copy buttons from original list so they do not get processed twice
      for (const copyBtn of btns.copybtns) {
        const id = copyBtn.id;
        delete allButtons[id];
      }

      btns.clone = { btn: allButtons.clone, disabled: true };
      btns.edit = { btn: allButtons.edit, disabled: true };
      btns.rdp = { btn: allButtons.rdp, disabled: true };
      if (status === CloudStatus.Enum.TERMINATED) {
        btns.start = { btn: allButtons.start, disabled: true };
      } else if (status === CloudStatus.Enum.PAUSED) {
        btns.resume = { btn: allButtons.resume, disabled: true };
      } else if (status === CloudStatus.Enum.RUNNING) {
        btns.pause = { btn: allButtons.pause, disabled: true };
      }
      btns.remove = { btn: allButtons['hard-delete'], disabled: true };

      if (allButtons.outputs && Array.isArray(allButtons.outputs)) {
        btns.outputs = allButtons.outputs;
      }
      return btns;
    }

    supportsRDP() {
      return true;
    }

    handleCopyCloudLinkToClipboard(cloudLink) {
      if (cloudLink && typeof cloudLink === 'object' && cloudLink.link) {
        const desc = this.getClipboardCloudLinkDescription(cloudLink);
        const successText = DojoString.substitute(I18NStringResource.computeResourcePageActionCopyCloudLinkSuccess, [desc]);
        const promise = this.copyCloudLinkToClipboard(cloudLink);
        promise
          .then(
            function () {
              Util.notify('NORMAL', successText);
            }.bind(this)
          )
          .catch(
            function (error) {
              Util.consoleLogWarning('handleCopyCloudLinkToClipboard', error);
              Util.notify('WARNING', error);
            }.bind(this)
          );
      } else {
        throw new TypeError('Invalid cloudLink argument or invalid cloudLink.link value');
      }
    }

    handleCopyPasswordToClipboard() {
      const promise = this.copyPasswordToClipboard();
      promise
        .then(
          function () {
            Util.notify('NORMAL', I18NStringResource.computeResourcePageActionCopyPasswordSuccess);
          }.bind(this)
        )
        .catch(
          function (error) {
            Util.consoleLogWarning('handleCopyPasswordToClipboard', error);
            Util.notify('WARNING', error);
          }.bind(this)
        );
    }

    handleCopySharingURLToClipboard() {
      const promise = this.copyShareResourceURLToClipboard();
      promise
        .then(
          function () {
            Util.notify('NORMAL', I18NStringResource.computeResourcePageActionShareResourceSettingsSuccess);
          }.bind(this)
        )
        .catch(
          function (error) {
            Util.consoleLogWarning('handleCopySharingURLToClipboard', error);
            if (error === 'is_sunsetted') {
              Util.notify('ERROR', I18NStringResource.sharingURLis_sunsetted);
            } else {
              Util.notify('WARNING', error);
            }
          }.bind(this)
        );
    }

    copyPasswordToClipboard() {
      const password = this.getCloudResource().getPassword();
      const onSuccessFn = () => {};
      const onFailureFn = (error) => {
        throw new Error(error);
      };
      try {
        Util.copyToClipboard(password, onSuccessFn, onFailureFn);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    copyCloudLinkToClipboard(cloudLink) {
      if (cloudLink && typeof cloudLink === 'object' && cloudLink.link) {
        const link = cloudLink.link;
        const onSuccessFn = () => {};
        const onFailureFn = (error) => {
          throw new Error(error);
        };
        try {
          Util.copyToClipboard(link, onSuccessFn, onFailureFn);
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(new TypeError('Invalid cloudLink argument or invalid cloudLink.Link value'));
      }
    }

    async copyShareResourceURLToClipboard() {
      try {
        const shareResourceUrl = await this.getShareResourceURL();
        const onSuccessFn = () => {};
        const onFailureFn = (error) => {
          throw new Error(error);
        };
        Util.copyToClipboard(shareResourceUrl, onSuccessFn, onFailureFn);
        return Promise.resolve();
      } catch (error) {
        let msg = error;
        if (typeof error === 'object' && error.message) {
          msg = error.message;
        }
        return Promise.reject(msg);
      }
    }

    async getRuleBodyParamIDs(ruleId, location) {
      let rule;
      let bodyParams;
      const bodyParamIds = [];
      const workflowDataService = this.getCollection().getDataService().workflow;
      try {
        const resp = await workflowDataService.getRuleById(ruleId, location, false, true, true);
        if (resp && resp.length === 1) {
          rule = resp[0];
        }
        bodyParams = rule.params.body.params;
        for (const param of bodyParams) {
          if (
            param.type !== 'username' &&
            param.type !== 'password' &&
            param.type !== 'external_public_cidr' &&
            param.id !== 'mw-credential-id' &&
            param.id !== 'mw-name' &&
            param.id !== 'mw-subscription-id'
          ) {
            bodyParamIds.push(param.id);
          }
        }
      } catch (error) {
        Util.consoleLogWarning('getShareResourceURL', error);
      }
      return bodyParamIds;
    }

    getBaseSharingURL() {
      const currentLocation = new URL(window.location);
      const protocol = currentLocation.protocol;
      const hostname = currentLocation.hostname;
      const port = currentLocation.port;
      const origin = currentLocation.origin;
      const product = Util.convertProductToUserFriendlyProduct(this.getConfig().params.product);
      const action = 'create';
      let baseURL = '';
      if (origin) {
        baseURL = `${origin}/resource/${product}/${action}`;
      } else {
        baseURL = `${protocol}://${hostname}`;
        if (port) {
          baseURL += `:${port}`;
        }
        baseURL += `/${product}/${action}`;
      }
      return baseURL;
    }

    async getShareResourceURL() {
      let shareResourceURL = '';
      const config = this.getConfig();
      if (config && config.eol) {
        const status = RuleInfo._EOLStatusNames[config.eol.Status] || RuleInfo._EOLStatusNames[RuleInfo.StatusIntNotSet];
        if (status === 'SUNSET') {
          throw new Error('is_sunsetted');
        }
      }
      const platform = config.params.cloud_provider;
      const accountId = await this.getCollection().getDataService().ui.getAccountIdFromConfig(config);
      const credentialTypeId = Util.getCredentialTypeIdFromRule(config.params);
      const configValues = { accountId: accountId, credentialTypeId: credentialTypeId, platform: platform };
      configValues.ruleId = config.rules_id;
      const location = config.params['mw-cloud-location'];
      const bodyParamIds = await this.getRuleBodyParamIDs(configValues.ruleId, location);
      // The config contains many more properties than are exposed in the create Wizard.
      // We just want to get what's needed for the create wizard, minus items that are either
      // hard-set, like username, or are unique to the user of the moment, like IP address or password.
      // Here, we try to filter out the unneeded items (keeping the resulting URL shorter as a result).
      if (config.params) {
        for (const [key, value] of Object.entries(config.params)) {
          if (bodyParamIds.includes(key)) {
            configValues[key] = encodeURIComponent(value);
          }
        }
      }
      const queryParams = Util.convertStringPropertiesToQueryParams(configValues, []);
      shareResourceURL = `${this.getBaseSharingURL()}${queryParams}`;
      return shareResourceURL;
    }

    supportsDuplicateURL() {
      return Util.isCreateDuplicateURLEnabled(this.getResourceType());
    }

    createButtons(config) {
      const configId = config.id;
      const buttons = {};
      const eol = config.eol;
      const isSunsetted = Boolean(eol && eol.Status === 3);
      const hasBeenStarted = Boolean(config.cloud_machines && config.cloud_machines.length === 1 && config.cloud_machines[0].private_ip);
      const skipButton = Boolean(isSunsetted && !hasBeenStarted);
      const logFn = this.getLogMethod();
      const row = this.getElement();
      const workflowDataService = this.getCollection().getDataService().workflow;
      const cloneClickHandler = this.makeCloneClickHandler(row, configId);
      const stopText = I18NStringResource.computeResourcePageActionStop;
      const stopTooltip = I18NStringResource.computeResourcePageActionStopTooltip;
      const stopAction = workflowDataService.stop.bind(workflowDataService);
      let pauseText = I18NStringResource.computeResourcePageActionPause;
      let pauseTooltip = I18NStringResource.computeResourcePageActionPauseTooltip;
      let pauseAction = workflowDataService.pause.bind(workflowDataService);
      if (config && config.params && config.params.initial_startup_check === 'template_only') {
        pauseText = stopText;
        pauseTooltip = stopTooltip;
        pauseAction = stopAction;
      }
      buttons.start = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionStart,
        I18NStringResource.computeResourcePageActionStartTooltip,
        'startIcon smallIcon',
        this.getResourceType(),
        workflowDataService.start.bind(workflowDataService),
        'start',
        this,
        config,
        'resourceStartBtn',
        logFn,
        true
      );
      buttons.stop = LandingPageNavButtons.createAction(
        stopText,
        stopTooltip,
        'stopIcon smallIcon',
        this.getResourceType(),
        stopAction,
        'stop',
        this,
        config,
        'resourceStopBtn',
        logFn,
        true
      );
      buttons.resume = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionResume,
        I18NStringResource.computeResourcePageActionResumeTooltip,
        'resumeIcon smallIcon',
        this.getResourceType(),
        workflowDataService.resume.bind(workflowDataService),
        'resume',
        this,
        config,
        'resourceResumeBtn',
        logFn,
        true
      );
      buttons.pause = LandingPageNavButtons.createAction(
        pauseText,
        pauseTooltip,
        'pauseIcon smallIcon',
        this.getResourceType(),
        pauseAction,
        'pause',
        this,
        config,
        'resourcePauseBtn',
        logFn,
        true
      );
      buttons.archive = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionArchive,
        I18NStringResource.computeResourcePageActionArchiveTooltip,
        'archiveIcon smallIcon',
        this.getResourceType(),
        workflowDataService.deactivate.bind(workflowDataService),
        'archive',
        this,
        config,
        'resourceArchiveBtn',
        logFn,
        true
      );
      buttons['hard-delete'] = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionArchive,
        I18NStringResource.computeResourcePageActionArchiveTooltip,
        'archiveIcon smallIcon',
        this.getResourceType(),
        workflowDataService.deactivate.bind(workflowDataService),
        'hard-delete',
        this,
        config,
        'resourceHardDeleteBtn',
        logFn,
        true
      );
      buttons['copy-password'] = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionCopyPassword,
        I18NStringResource.computeResourcePageActionCopyPasswordTooltip,
        'cloneIcon smallIcon',
        this.getResourceType(),
        this.handleCopyPasswordToClipboard.bind(this),
        '',
        this,
        config,
        'resourceCopyPasswordBtn',
        logFn,
        false
      );
      if (this.supportsDuplicateURL()) {
        if (!isSunsetted) {
          buttons['copy-shareresource'] = LandingPageNavButtons.createAction(
            I18NStringResource.computeResourcePageActionShareResourceSettings,
            I18NStringResource.computeResourcePageActionShareResourceSettingsTooltip,
            'cloneIcon smallIcon',
            this.getResourceType(),
            this.handleCopySharingURLToClipboard.bind(this),
            '',
            this,
            config,
            'resourceShareBtn',
            logFn,
            false
          );
        }
      }
      buttons.edit = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionEdit,
        I18NStringResource.computeResourcePageActionEditTooltip,
        'editIcon smallIcon',
        this.getResourceType(),
        workflowDataService.edit.bind(workflowDataService),
        '',
        this,
        config,
        'resourceEditBtn',
        logFn,
        false
      );
      if (!isSunsetted) {
        buttons.clone = LandingPageNavButtons.createAction(
          I18NStringResource.computeResourcePageActionClone,
          I18NStringResource.computeResourcePageActionCloneTooltip,
          'cloneIcon smallIcon',
          this.getResourceType(),
          cloneClickHandler,
          '',
          this,
          config,
          'resourceCloneBtn',
          logFn,
          false
        );
      }
      let stdLinks = null;
      let copyLinks = null;
      if (config.cloud_links) {
        const cloudLinks = config.cloud_links;
        const specialCommandsMgr = new SpecialCommandsManager({ productCollection: this.getCollection() });

        // extract special command cloud links
        const specialCmds = specialCommandsMgr.extractSpecialCommandCloudLinks(cloudLinks);
        specialCommandsMgr.addSpecialCommandButtons(specialCmds, buttons, config, this.getResourceType(), this.getLogMethod(), this);

        Object.entries(cloudLinks).sort((val1, val2) => val1[1].sort_order - val2[1].sort_order);

        for (const [id, cloudLink] of Object.entries(cloudLinks)) {
          if (cloudLink.use_clipboard) {
            if (!copyLinks) {
              copyLinks = {};
            }
            copyLinks[id] = cloudLink;
          } else {
            if (!stdLinks) {
              stdLinks = {};
            }
            stdLinks[id] = cloudLink;
          }
        }
      }
      if (stdLinks) {
        const cloudLinks = stdLinks;
        Object.entries(cloudLinks).sort((val1, val2) => val1[1].sort_order - val2[1].sort_order);

        for (const [id, cloudLink] of Object.entries(cloudLinks)) {
          const btnId = `cta-${cloudLink.description}`;
          const btn = this.getGenericConnectButton(cloudLink.description, btnId);

          let connectBtn;
          if (!buttons.connect) {
            connectBtn = this.getGenericConnectButton(cloudLink.description, btnId, 'connectCtaBtn');
            buttons.connect = connectBtn;
          }

          for (const [k, v] of Object.entries(cloudLink)) {
            btn.dataset[`cloudlink_${k}`] = v;
            if (connectBtn) {
              connectBtn.dataset[`cloudlink_${k}`] = v;
            }
          }
          buttons[btnId] = btn;
        }
      }
      if (!buttons.connect) {
        buttons.connect = document.createElement('span');
      }

      this.addCopyLinkButtons(buttons, copyLinks);

      return buttons;
    }

    getClipboardCloudLinkDescription(cloudLink) {
      const i18NId = `dynamicConnectBtn_${cloudLink.description}`;
      let desc;
      if (I18NStringResource[i18NId]) {
        desc = I18NStringResource[i18NId];
      } else {
        desc = Util.underscoreToTitleCase(cloudLink.description, false, true);
      }
      return desc;
    }

    getClipboardCloudLinkTooltip(cloudLink, fallbackValue = '') {
      const i18NId = `dynamicConnectBtnTooltip_${cloudLink.description}`;
      let tip;
      if (I18NStringResource[i18NId]) {
        tip = I18NStringResource[i18NId];
      } else {
        tip = DojoString.substitute(I18NStringResource.computeResourcePageActionCopyCloudLinkTooltip, [fallbackValue]);
      }
      return tip;
    }

    // modifies passed in buttons argument, or returns new object if no buttons argument provided.
    addCopyLinkButtons(buttons, copyLinks) {
      if (!buttons || typeof buttons !== 'object') {
        if (!buttons) {
          buttons = {};
        } else {
          throw new TypeError('Invalid buttons argument');
        }
      }
      if (copyLinks && Object.entries(copyLinks).length > 0) {
        buttons.outputs = [];
        let clItems = Object.values(copyLinks).sort((val1, val2) => val1.sort_order - val2.sort_order);
        for (const cloudLink of clItems) {
          const btnId = `cl-${cloudLink.description}`;
          const desc = this.getClipboardCloudLinkDescription(cloudLink);
          const label = DojoString.substitute(I18NStringResource.computeResourcePageActionCopyCloudLink, [desc]);
          const tooltip = this.getClipboardCloudLinkTooltip(cloudLink, desc);
          buttons.outputs.push(
            LandingPageNavButtons.createAction(
              label, // button label
              tooltip, // button tooltip
              'cloneIcon smallIcon', // iconClasses
              this.getResourceType(), // product
              this.handleCopyCloudLinkToClipboard.bind(this, cloudLink), // onClick handler
              'cloudLink', // action type
              this, // row
              this.getConfig(), // item that the row corresponds to
              btnId, // button ID
              this.getLogMethod(), // Logging function
              false // is life cycle action (e.g., Start, Stop, etc.)
            )
          );
        }
      }
      return buttons;
    }

    getLoggingService() {
      const loggingService = this.getCollection().getDataService().logging;
      return loggingService;
    }

    getLogMethod() {
      const loggingService = this.getCollection().getDataService().logging;
      const logFn = loggingService.logData.bind(loggingService);
      return logFn;
    }

    createCTAButtons(config) {
      const configId = config.id;
      const ctaButtons = {};
      const logFn = this.getLogMethod();
      const row = this.getElement();
      const workflowDataService = this.getCollection().getDataService().workflow;
      const stopText = I18NStringResource.computeResourcePageActionStop;
      const stopTooltip = I18NStringResource.computeResourcePageActionStopTooltip;
      const stopAction = workflowDataService.stop.bind(workflowDataService);
      let pauseText = I18NStringResource.computeResourcePageActionPause;
      let pauseTooltip = I18NStringResource.computeResourcePageActionPauseTooltip;
      let pauseAction = workflowDataService.pause.bind(workflowDataService);
      if (config && config.params && config.params.initial_startup_check === 'template_only') {
        pauseText = stopText;
        pauseTooltip = stopTooltip;
        pauseAction = stopAction;
      }

      ctaButtons.startCTA = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionStart,
        I18NStringResource.computeResourcePageActionStartTooltip,
        'startIcon smallIcon',
        this.getResourceType(),
        workflowDataService.start.bind(workflowDataService),
        'start',
        this,
        config,
        'startCtaBtn',
        logFn,
        true
      );
      ctaButtons.stopCTA = LandingPageNavButtons.createAction(
        stopText,
        stopTooltip,
        'stopIcon smallIcon',
        this.getResourceType(),
        stopAction,
        'stop',
        this,
        config,
        'stopCtaBtn',
        logFn,
        true
      );
      ctaButtons.resumeCTA = LandingPageNavButtons.createAction(
        I18NStringResource.computeResourcePageActionResume,
        I18NStringResource.computeResourcePageActionResumeTooltip,
        'resumeIcon smallIcon',
        this.getResourceType(),
        workflowDataService.resume.bind(workflowDataService),
        'resume',
        this,
        config,
        'resumeCtaBtn',
        logFn,
        true
      );
      ctaButtons.pauseCTA = LandingPageNavButtons.createAction(
        pauseText,
        pauseTooltip,
        'pauseIcon smallIcon',
        this.getResourceType(),
        pauseAction,
        'pause',
        this,
        config,
        'pauseCtaBtn',
        logFn,
        true
      );
      return ctaButtons;
    }

    async updateAutoTerminationSpinner(cloudResource, status) {
      let rowSelector = this.getElementSelector();
      if (cloudResource && cloudResource.config && cloudResource.config.params && cloudResource.config.params['mw-termination-policy']) {
        const termPolicy = cloudResource.config.params['mw-termination-policy'];
        if (termPolicy > 0 || termPolicy === 'never' || termPolicy === 'on_idle') {
          if (status && status === CloudStatus.Enum.RUNNING) {
            await cloudResource.startCountdown(rowSelector);
          } else {
            cloudResource.stopCountdown(rowSelector);
          }
        }
      }
    }

    updateNotificationRowElement(cloudResource, errors) {
      if (!cloudResource || typeof cloudResource !== 'object') {
        return;
      }
      let row = this.getElement();
      let options = cloudResource.getOptions();
      let notificationsButton = row.querySelector('div.resource-notification-count');
      try {
        let errorText = '';
        if (errors && Array.isArray(errors) && errors.length > 0) {
          for (const err of errors) {
            errorText += `${err.reason} `;
          }
          errorText = errorText.trim();
        }
        if (notificationsButton.dataset.notifications != errorText) {
          notificationsButton.innerHTML = '';
          notificationsButton.dataset.notifications = '';
          if (errorText !== '') {
            notificationsButton.dataset.notifications = errorText;

            let btn = document.createElement('button');
            btn.type = 'button';
            btn.className = 'btn displayNotificationsBtn';
            btn.title = I18NStringResource.notifications;
            let span1 = document.createElement('span');
            span1.className = 'visually-hidden';
            span1.innerHTML = I18NStringResource.viewNotifications;
            btn.appendChild(span1);
            let span2 = document.createElement('span');
            span2.className = 'icon-alert-error';
            btn.appendChild(span2);
            btn.addEventListener('click', this.displayResourceNotifications.bind(this), false);
            notificationsButton.appendChild(btn);
          }
        }
      } catch (error) {
        /* do nothing as we most likely left page */
      }
    }

    updateIPRowElement(cloudResource) {
      if (!cloudResource || typeof cloudResource !== 'object') {
        return;
      }
      let row = this.getElement();
      let rowSelector = this.getElementSelector();
      let ipDiv;
      let options = cloudResource.getOptions();
      let ipAddressContainer = row.querySelector('div.ip-address');
      try {
        cloudResource.stopCountdown(rowSelector);
        if (!options.autoRefresh && options.ip !== 'TBD') {
          ipDiv = cloudResource.displayIP();
        }
        if (ipAddressContainer && ipDiv) {
          ipAddressContainer.innerHTML = '';
          ipAddressContainer.appendChild(ipDiv);
        }
      } catch (error) {
        /* do nothing as we most likely left page */
      }
    }

    static createEmptyCollectionWarningItem() {
      let emptyCollectionRow = document.createElement('li');
      emptyCollectionRow.classList.add('emptyTable');
      emptyCollectionRow.textContent = I18NStringResource.computeResourcePageDefaultResourcesMsg;
      return emptyCollectionRow;
    }

    static findEmptyCollectionWarningItem(collection) {
      let emptyCollectionWarningRow;
      if (collection) {
        emptyCollectionWarningRow = collection.querySelector('li.emptyTable');
      }
      return emptyCollectionWarningRow;
    }

    static createCollectionHeader(columns) {
      let header = document.createElement('li');
      header.classList.add('item', 'item-container', 'table-header');
      for (let columnInfo of columns) {
        let div = document.createElement('div');
        let strIndex = columnInfo.id;
        let strValue = I18NStringResource[strIndex];
        div.classList.add('attribute', columnInfo.class);
        div.dataset.name = strValue;
        if (columnInfo.displaytext) {
          div.textContent = strValue;
        }
        if (columnInfo.iconclass) {
          let span = document.createElement('span');
          span.className = columnInfo.iconclass;
          span.title = I18NStringResource.notifications;
          div.appendChild(span);
        }
        header.appendChild(div);
      }
      return header;
    }

    static getStep1FieldNames() {
      // use of this is not an error.
      // it refers to the referencing class
      return this.STEP1_FIELD_LIST;
    }
  }
  /* class static member variables */
  Object.defineProperty(CollectionItem, "PAUSE_UPDATE_STATUS_ON_DELETE_INTERVAL", {
    value: 20000,
    writable: false,
    configurable: false,
    enumerable: true
  });
  Object.defineProperty(CollectionItem, "REFRESH_STATUS_INTERVAL", {
    value: 20000,
    writable: false,
    configurable: false,
    enumerable: true
  });
  Object.defineProperty(CollectionItem, "REFRESH_STATUS_ITERATION_LIMIT", {
    value: 100,
    writable: false,
    configurable: false,
    enumerable: true
  });
  // default list. Override in subclass as needed.
  Object.defineProperty(CollectionItem, "STEP1_FIELD_LIST", {
    value: [
      {
        name: "mw-name",
        formatterId: "mlName"
      },
      {
        name: "version",
        formatterId: "releaseSelector"
      },
      {
        name: "cloud_provider",
        formatterId: "cloudPlatform"
      },
      {
        name: "cloudData.account",
        formatterId: "credentialSelector"
      },
      {
        name: "operating_system",
        formatterId: "osName"
      },
      {
        name: "mw-cloud-location",
        formatterId: "cloudLocationSelector"
      }
    ],
    writable: false,
    configurable: false,
    enumerable: false
  });

  return CollectionItem;
});
