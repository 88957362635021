/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "templates/computeResourcePage",
  "computeResource/collections/collectionFactory",
  "dialogs/warningDialog",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util",
  "productUIGenerator",
  "supportedProducts",
  "wizard/createMatlabWizard",
  "wizard/ruleManager",
  "service/cachedDataService",
  "service/cloudResource",
  "service/cloudStatus",
  "service/landingPageNavButtons"
], function( _, $, AbstractPage, PageTemplate, CollectionFactory,
             WarningDialog, I18NStringResource, DojoString, Util,
             ProductUIGenerator, SupportedProducts, CreateMATLABWizard,
             RuleManager, CachedDataService, CloudResource,CloudStatus,
             LandingPageNavButtons ) {

  /**
   * This subclass of MatlabDriveApplication uses
   * Mathworks FileBrowser2 widget for the tree controller.
   */
  class ComputeResourcePage extends AbstractPage {

    constructor (params) {
      super(params);
      this.events = {
        "click div#computeResourcePageView > div.facetBar > ul.facets > li > a#credentialLink" : "goToCredential",
        "dragover div.computeContainer": "dragoverNotAllowed",
        "click div.matlabContainer ol.collection-container li.item-container a.goToResourceDetail": "goToResourceDetail",
        "click div#computeResourcePageView button.viewPassword" : "toggleViewPassword",
        "click button.btn.btn-link.default-link.ComputeResourcePage" : "goToHome"
      };

      this.pageTemplate = PageTemplate;
      // local caching for this page and also small supporting objects that
      // it uses, like RuleManager or Collection.
      if (Util.clientCachingIsEnabled()) {
        this.localDataService = new CachedDataService({
          baseService: this.getDataService()
        });
        this.initLocalDataService();
      } else {
        this.localDataService = this.getDataService();
      }
      RuleManager.initializeRuleManagerProductMap(this.localDataService);
      this.userProfile = null;
      this.customCreateData = null;
      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, "logoutPage", "getAuthManager", "goToCredential",
                "goToResourceDetail", "toggleViewPassword",
                "goToHome");
    }

    /* istanbul ignore next */
    getRuleManagerByProduct (product) { return RuleManager.getRuleManagerForProduct(product); }
    /* istanbul ignore next */
    getResourceCollectionByProduct (product) { return CollectionFactory.getProductCollection(product); }

    setUserProfile (profile) { this.userProfile = profile; }
    getUserProfile () { return this.userProfile; }

    setCustomCreateData (data) {
      this.customCreateData = data;
    }
    getCustomCreateData () {
      return this.customCreateData;
    }

    getLocalDataService () { return this.localDataService; }

    initLocalDataService () {
      this.getLocalDataService().init(
        [
          "workflow",
          "ui",
          "logging",
          "legacy_parallel/cluster",
          "legacy_parallel/credential",
          "legacy_parallel/global",
          "user"
        ], // components to add to localDataService
        // APIs to cache
        [
          {
            set: "workflow/list",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/search",
            clear: null
          },
          {
            set: "workflow/listConfigs",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/details",
            clear: [
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/defaultRuleByProduct",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/defaultRule",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "workflow/listRulesByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "workflow/getRuleById",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete"
            ]
          },
          {
            set: "ui/listSection2UIElementsByRuleId",
            clear: [
              "workflow/create",
              "workflow/update"
            ]
          },
          {
            set: "ui/getRulesArrayByProduct",
            clear: [
              "workflow/create",
              "workflow/update",
              "workflow/hardDelete",
            ]
          },
          {
            set: "ui/listCredentials",
            clear: [
              "ui/updateCredentialDescription",
              "ui/deleteCredential",
              "ui/createCredential",
              "ui/importCredential"
            ]
          },
          {
            set: "user/profile",
            clear: null
          }
        ]
      );
    }

    goToResource (event, data) {
      if (data && typeof data === 'object' && ("path" in data)) {
        const path = data.path;
        $.event.trigger("changetoresourcepage:ccwa", {path: path, trigger: false, replace: false});
      }
    }

    // override
    goToHome (event) {
      //goToResource is overridden on some pages - goToHome should not be
      //if (event && event.preventDefault) { event.preventDefault(); }
      this.logEvent(event);
      const computeContainer = document.querySelector('div#computeContainer.computeContainer');
      const wizardContainer = document.querySelector('div#createWizardContainer.wizardContainer');
      let wizardBeingDisplayed = false;
      if (computeContainer && wizardContainer) {
        if (wizardContainer.style.display && wizardContainer.style.display !== 'none' && computeContainer.style.display === 'none') {
          wizardBeingDisplayed = true;
        }
      }
      if (!wizardBeingDisplayed) {
        $.event.trigger("changetoresourcepage:ccwa", {path: "/"});
      }
    }

    async hasMatlabCredential () {
      return await this.getRuleManagerByProduct('matlab').hasCredentials("any",false);
    }

    /* istanbul ignore next */
    hasProductInfo (product) { return !!(AbstractPage.GetProductInfo(product)); }
    /* istanbul ignore next */
    setProductInfo (product, value) { return AbstractPage.SetProductInfo(product, value); }
    /* istanbul ignore next */
    getProductInfo (product) { return AbstractPage.GetProductInfo(product); }

    async syncMatlabCredentials (attemptNumber) {
      try {
        const hasCredential = await this.hasMatlabCredential();
        if (hasCredential) {
          this.getLocalDataService().legacy_parallel.credential.syncCred(); // Call CC1's cred sync. No need to await since CC1 would return a response and work on cred sync in a async manner.
        }
      } catch (error) {
        Util.consoleLogWarning("syncMatlabCredentials", error);
      }
    }

    startPage (msgHandler) {
      super.startPage();
      if (!msgHandler || typeof msgHandler !== "function") {
        throw new TypeError("Invalid message handler argument");
      }
      $(document).on(CreateMATLABWizard.CANCEL_WIZARD_EVENT, this.stopCreateMATLABWizard.bind(this));
    }

    stopPage () {
      super.stopPage();
      $(document).off(CreateMATLABWizard.CANCEL_WIZARD_EVENT);
      CloudResource.stopAll();
      this.turnOffStartWizardButtonListeners();
    }

    async showCredentialWarningDialog () {
      const currentPage = document.querySelector('div#cloudCenterApp.appContainer > div.pageView');
      let pageId = "";
      if (currentPage) { pageId = currentPage.id; }
      if (pageId !== "computeResourcePageView") {
        return;
      }
      const checkCredAndDirect = async function() {
        const hasCredential = await this.hasMatlabCredential(); // Check it again when user click on "Get Started" in welcome screen, because CC1 cred sync might already populate the credentials while user is reading the welcome screen.
        if (hasCredential) {
          this.goToHome();
        } else {
          this.goToCredential();
        }
      }
      const warningDialog = new WarningDialog({
        hideIcon: true,
        title: I18NStringResource.warningTitleNeedCredential,
        text: I18NStringResource.warningTextNeedCredential,
        actionButtonLabel: I18NStringResource.warningActionLabelNeedCredential,
        closeButtonLabel: I18NStringResource.warningCloseLabel,
        modalContainerId: "modalContainer",
        classname: "noCredentialsWarning",
        actionFn: checkCredAndDirect.bind(this)
      });
      warningDialog.show();
    }

    stopCreateMATLABWizard (event, data) {
       let url = "";
       let openTarget = "_self";
       if (event && event.preventDefault) { event.preventDefault(); }
       if (event && event.currentTarget) {
         // Register button click with DDUX if we have a button ID
         this.logEvent(event);
       }
      if (data && typeof data === 'object') {
        if ("href" in data && data.href) {
          url = data.href;
          if (data && data.target) {
            openTarget = data.target;
          }
        }
        if ("configId" in data) {
          let showRow = async function (id) {
            try {
              const configData = await this.getLocalDataService().workflow.list("config", null, {config_id:id, refresh:true});
              if (configData && configData[0]) {
                const config = configData[0];
                const row = this.getResourceCollectionByProduct(config.params.product).createCollectionRow(config);
                SupportedProducts.hideNoProductResourcesMessage(config.params.product);
                Util.notify("NORMAL", DojoString.substitute(I18NStringResource.computeResourcePageActionCreateSuccessWithInfo, [config.params["mw-name"]]));
                this.getResourceCollectionByProduct(config.params.product).appendRow(row);
                row.updateStatus();
                return;
              }
            } catch (error){
              Util.consoleLogError("stopCreateMATLABWizard", error);
            } finally {
              this.removeWizard();
              if (data && data.wizardInstance) {
                data.wizardInstance.remove();
              }
            }
            Util.notify("NORMAL", I18NStringResource.computeResourcePageActionCreateSuccessWithoutInfoAndRefresh);
          }.bind(this);
          // clear all caches related to Rules
          this.getLocalDataService().clear("workflow/list");
          this.getLocalDataService().clear("workflow/search");
          this.getLocalDataService().clear("workflow/listConfigs");
          this.getLocalDataService().clear("ui/getRulesArrayByProduct");
          showRow(data.configId);
        }
      }
      if (!data || typeof data !== 'object' || !("configId" in data)) {
        this.removeWizard();
      }
      if (url && openTarget) {
        Util.openWindow(url, openTarget);
      }
    }

    removeWizard () {
      const wizardContainerElement = document.querySelector('div#computeResourcePageView div#createWizardContainer');
      if (!wizardContainerElement) { throw new Error(`Element not found: 'div#computeResourcePageView div#createWizardContainer'`); }
      const computeContainer = document.querySelector('div#computeResourcePageView div#computeContainer');
      if (!computeContainer) { throw new Error(`Element not found: 'div#computeResourcePageView div#computeContainer'`); }
      const wizardStepContainer = wizardContainerElement.querySelector('div#createWizardStepsContainer');
      if (!wizardStepContainer) { throw new Error(`Element not found: 'div#createWizardStepsContainer'`); }
      wizardStepContainer.innerHTML = '';
      wizardContainerElement.style.display = 'none';
      computeContainer.style.display = 'block';
      document.body.style.height = "100%";
      this.setCustomCreateData(null);
      this.goToResource(null, {path: "/", trigger: false, replace: false});
    }

    pageURLSpecifiesCreate () {
      let createSpecifiedInPageURL = false;
      if (this.urlArgs && typeof this.urlArgs === 'object' && this.urlArgs.path === 'create') {
        createSpecifiedInPageURL = true;
      }
      return createSpecifiedInPageURL;
    }

    async render () {
      const createSpecifiedInPageURL = this.pageURLSpecifiesCreate();
      if (Util.isCreateDuplicateURLEnabled() && createSpecifiedInPageURL) {
        await this.extractCreateDataFromPageURL();
      } else {
        this.setCustomCreateData(null);
      }

      this.getLocalDataService().clear("user/profile");
      this.getLocalDataService().clear("ui/getRulesArrayByProduct");
      this.getLocalDataService().clear("ui/listCredentials");
      this.setUserProfile(await this.getLocalDataService().user.profile());
      const userProfile = this.getUserProfile();
      CollectionFactory.initializeProductCollectionsForUser(userProfile, this.getDataService());
      const am = this.getAuthManager();
      const touchevents = Util.touchEventsEnabled();
      const products = (userProfile && typeof userProfile.products === "object") ? userProfile.products : {matlab: {}, cluster: {}};
      if (! products.hasOwnProperty('matlab')) {
        products.matlab = {};
      }
      if (! products.hasOwnProperty('cluster')) {
        products.cluster = {};
      }

      const hasAzureCredential = await this.getRuleManagerByProduct('matlab').hasCredentials("azure", false);
      const hasAWSCredential = await this.getRuleManagerByProduct('matlab').hasCredentials("aws", false);
      if (!hasAzureCredential && !hasAWSCredential) {
        delete products["parallel_server"];
      }
      if (products["parallel_server"] && hasAzureCredential && !hasAWSCredential) {
        delete products["cluster"];
      }

      const credentialData = {
        credentials: {
          azure: hasAzureCredential,
          aws: hasAWSCredential,
          legacyClusterEnabled: !!products["cluster"],
          newClusterEnabled: !!products["parallel_server"]
        },
        products: products
      };

      const productUIGenerator = new ProductUIGenerator();
      productUIGenerator.setCredentialData(credentialData);
      const productsHTML = productUIGenerator.getProductsHTML(products);
      const templateParams = {
        i18n: I18NStringResource,
        headerNavigation: this.renderNavigationHeader(),
        productsHTML: productsHTML
      };
      let htmlResult = this.pageTemplate(templateParams);
      this.$el.empty().html(htmlResult);

      if (!touchevents) {
        try {
          $('[data-bs-toggle="tooltip"]').tooltip();
        } catch (e) {
          Util.notify('ERROR', I18NStringResource.computeResourcePageErrorLoadingInitialPage);
        }
      }

      this.renderMatrix();
      await this.syncMatlabCredentials();
      const collectionIterator = CollectionFactory.getUserSupportedCollectionsIterator();
      let collectionKey = collectionIterator.next();
      while (!collectionKey.done) {
        try {
          const product = collectionKey.value;
          if (product in products) {
            const collection = CollectionFactory.getProductCollection(product);
            await collection.initializeCache();
            collection.autoCancelRenderList();
            collection.startCreateWizardButtonClickListener();
            collection.enableStartWizardButton();
          }
        } catch (error) {
          Util.consoleLogError("render", error);
        }
        collectionKey = collectionIterator.next();
      }

      const productKeys = Object.keys(products); //defined at start of render()
      // if the user has no products, we still add empty objects with keys "matlab" and "cluster".
      // Those have no keys themselves.
      let hasAtLeastOneCredential = false;
      for (const pKey of productKeys) {
        if (Object.keys(products[pKey]).length) {
          hasAtLeastOneCredential = await this.getRuleManagerByProduct(pKey).hasCredentials("any", false);
          if (hasAtLeastOneCredential) {
            break;
          }
        }
      }
      if (!productKeys || productKeys.length === 0 || !hasAtLeastOneCredential) {
        const welcomeFn = async function() {
          await this.showCredentialWarningDialog();
        }.bind(this);
        welcomeFn();
      }
      const customCreateData = this.getCustomCreateData();
      if (customCreateData && customCreateData.product && Util.isCreateDuplicateURLEnabled(customCreateData.product)) {
        await this.startCustomCreate(customCreateData);
      }
    }

    async startCustomCreate (customCreateData) {
      if (customCreateData && typeof customCreateData === 'object') {
        const collection = this.getResourceCollectionByProduct(customCreateData.product);
        let path = `/${Util.convertProductToUserFriendlyProduct(customCreateData.product)}/create`;
        if (customCreateData.urlQueryParams && SupportedProducts.productSupportsDuplicateURL(customCreateData.product)) {
          const queryParams = Util.convertStringPropertiesToQueryParams(customCreateData.urlQueryParams, ["mw-credential-id", "mw-subscription-id"]);
          if (queryParams) {
            path += queryParams;
          }
        }
        this.goToResource(null, { path: path, trigger: false, replace: false });
        await collection.startCreateWizard(null, customCreateData.formValues);
      }
    }

    async extractCreateDataFromPageURL () {
      const productToCreate = this.urlArgs.product;
      const actionVerb = this.urlArgs.path;
      const nameValuePairs = this.urlArgs.params;
      const desiredAccountId = nameValuePairs.accountId;
      const desiredPlatform = nameValuePairs.platform;
      const desiredCredentialTypeId = nameValuePairs.credentialTypeId;
      let desiredCredentialData;
      if (desiredAccountId && desiredPlatform) {
        try {
          desiredCredentialData = await this.getDataService().ui.getCredentialDataFromAccountId(desiredAccountId, desiredCredentialTypeId);
        } catch (error) {
          Util.consoleLogError('getCredentialDataFromAccountId', error);
          Util.notify('ERROR', 'Unable to create resource with specified values');
          this.setCustomCreateData(null);
          this.goToHome();
        }
      }
      if (desiredCredentialData) {
        nameValuePairs["mw-credential-id"] = desiredCredentialData.credentialId;
        nameValuePairs.credentialTypeId = desiredCredentialData.credentialTypeId;
        if (desiredCredentialData.subscriptionId) {
          nameValuePairs["mw-subscription-id"] = desiredCredentialData.subscriptionId;
          // nameValuePairs["mw-credential-id"] += `(${desiredCredentialData.subscriptionId})`;
        }
      }
      if (actionVerb === 'create' && SupportedProducts.isValidProduct(productToCreate)) {
        this.setCustomCreateData({
          product: productToCreate,
          formValues: nameValuePairs,
          urlQueryParams: this.urlArgs.params
        });
      } else {
        this.setCustomCreateData(null);
      }
    }

    turnOffStartWizardButtonListeners () {
      const products = SupportedProducts.getProductNameList();
      for (let product of products) {
        const startWizardButtonId = SupportedProducts.getStartWizardButtonId(product);
        const startWizardButtonSelector = SupportedProducts.getStartWizardButtonSelector(product);
        $(startWizardButtonSelector).off("click");
      }
    }

  }

  return ComputeResourcePage;
}); // require
